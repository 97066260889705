body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.float-content {
  position:fixed;
  width:60px;
  height:60px;
  bottom:20px;
  right:100px;
  padding: 2px;
  background-color:#0C9;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
}
.icon-float {
  margin-top:22px;
}

.add-lines {
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.img-service {
  max-width: 100%;
  /*height: auto;*/
  height: 50% !important;
}
.img-new > img {
  /*max-width: 100%;*/
  /*height: auto;*/
  /*height: 50%;*/
}
